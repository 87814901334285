<template>
    <div>
        <b-alert :show="not_found" variant="danger" fade>
      <span class="text-light-75 font-weight-bold d-block font-size-lg">{{
        $t("TAG.NOT_FOUND")
      }}</span>
        </b-alert>
        <div v-if="!not_found" class="row">
            <div class="col-lg-6">
                <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0">
                        <h3 class="card-title font-weight-bolder text-dark">
                            {{ $t("TAG.DETAILS") }}
                        </h3>
                    </div>
                    <div class="card-body pt-0">
                        <!--begin::Content-->
                        <div class="row py-5">
                            <div class="col-lg-6">
                <span
                        class="text-dark-75 font-weight-bold d-block font-size-lg"
                >{{ $t("TAG.UID") }}</span
                >
                            </div>
                            <div class="col-lg-6 text-right">
                <span
                        class="text-dark-75 font-weight-bold d-block font-size-lg"
                >{{ tag.uid }}</span
                >
                            </div>
                        </div>
                        <div class="row py-5">
                            <div class="col-lg-6">
                <span
                        class="text-dark-75 font-weight-bold d-block font-size-lg"
                >{{ $t("TAG.STATUS.STATUS") }}</span
                >
                            </div>
                            <div class="col-lg-6 text-right">
                <span
                        class="label label-lg label-inline"
                        v-bind:class="`label-light-${parseTagStatus(tag.status)[0]}`"
                >{{ parseTagStatus(tag.status)[1] }}</span
                >
                            </div>
                        </div>
                        <div class="row py-5">
                            <div class="col-lg-6">
                <span
                        class="text-dark-75 font-weight-bold d-block font-size-lg"
                >{{ $t("TAG.SELLER") }}</span
                >
                            </div>
                            <div class="col-lg-6 text-right">
                <span
                        class="text-dark-75 font-weight-bold d-block font-size-lg"
                >{{ tag.seller }}</span
                >
                            </div>
                        </div>
                        <div class="row py-5">
                            <div class="col-lg-6">
                <span
                        class="text-dark-75 font-weight-bold d-block font-size-lg"
                >{{ $t("TAG.LIFECYCLE.LIFECYCLE") }}</span
                >
                            </div>
                            <div class="col-lg-6 text-right">
                <span
                        class="label label-lg label-inline"
                        v-bind:class="`label-light-${
                    tag.sold ? 'success' : 'warning'
                  }`"
                >{{
                    tag.sold
                      ? $t("TAG.LIFECYCLE.POSTSALE")
                      : $t("TAG.LIFECYCLE.PRESALE")
                  }}</span
                >
                            </div>
                        </div>
                        <div class="row py-5">
                            <div class="col-lg-6">
                <span
                        class="text-dark-75 font-weight-bold d-block font-size-lg"
                >{{ $t("TAG.SCANS") }}</span
                >
                            </div>
                            <div class="col-lg-6 text-right">
                <span
                        class="text-dark-75 font-weight-bold d-block font-size-lg"
                >{{ history.length }}</span
                >
                            </div>
                        </div>
                        <div class="row py-5">
                            <div class="col-lg-6">
                <span
                        class="text-dark-75 font-weight-bold d-block font-size-lg"
                >{{ $t("TAG.LAST_LOCATION") }}</span
                >
                            </div>
                            <div class="col-lg-6 text-right">
                <span
                        class="text-dark-75 font-weight-bold d-block font-size-lg"
                >{{
                    last_location ? last_location : $t("TAG.LOCATION_UNKNOWN")
                  }}</span
                >
                            </div>
                        </div>
                        <div class="row pt-5">
                            <div class="col-lg-6">
                <span
                        class="text-dark-75 font-weight-bold d-block font-size-lg"
                >{{ $t("TAG.LAST_SCAN") }}</span
                >
                            </div>
                            <div class="col-lg-6 text-right">
                <span
                        class="text-dark-75 font-weight-bold d-block font-size-lg"
                >{{
                    last_scan
                      ? fromNow(last_scan.scanned_at)
                      : $t("TAG.LAST_SCAN_NEVER")
                  }}</span
                >
                            </div>
                            <!--end::Content-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card card-custom gutter-b">
                    <div class="card-header card-rounded-bottom border-0 py-5">
                        <div>
                            <h3
                                    class="card-title font-weight-bolder text-dark"
                                    style="float: left"
                            >
                                {{ $t("TAG.SCAN_DETAILS") }}
                            </h3>
                            <span
                                    v-if="selected_scan && last_scan == selected_scan"
                                    class="label label-lg label-inline text-left mt-1"
                                    style="float: left"
                                    v-bind:class="`label-secondary`"
                            >{{ $t("TAG.LAST_SCAN") }}</span
                            >
                        </div>
                        <span class="text-muted font-weight-bold font-size-sm text-right"
                        >{{ selected_location }}<br/>
              {{
                selected_scan ? formatTime(selected_scan.scanned_at) : null
              }}</span
                        >
                    </div>
                    <l-map
                            :zoom="map.zoom"
                            :center="map.center"
                            :options="map.mapOptions"
                            style="height: 400px"
                            class="card-rounded"
                    >
                        <l-tile-layer
                                :url="map.url"
                                :attribution="map.attribution"
                        ></l-tile-layer>
                        <l-marker :lat-lng="map.center"></l-marker>
                    </l-map>
                </div>
            </div>
        </div>
        <div v-if="!not_found" class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">{{
            $t("TAG.HISTORY.HISTORY")
          }}</span>
                    <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
            $tc("TAG.HISTORY.SHOW_COUNT", paginatedHistory.length, {
              total: history.length,
            })
          }}</span>
                </h3>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body py-0">
                <!--begin::Table-->
                <div class="table-responsive" v-if="history.length">
                    <table
                            class="table table-head-custom table-vertical-center"
                            id="kt_advance_table_widget_4"
                    >
                        <thead>
                        <tr class="text-left">
                            <th class="pl-0" style="min-width: 120px">
                                {{ $t("TAG.HISTORY.TIME") }}
                            </th>
                            <th class="pl-0" style="min-width: 60px">
                                {{ $t("TAG.HISTORY.SECURE_SCAN") }}
                            </th>
                            <th style="min-width: 110px">
                                {{ $t("TAG.HISTORY.LATITUDE") }}
                            </th>
                            <th style="min-width: 110px">
                                {{ $t("TAG.HISTORY.LONGITUDE") }}
                            </th>
                            <th class="pr-0 text-right" style="min-width: 160px">
                                {{ $t("ACTIONS") }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="history in paginatedHistory">
                            <tr v-bind:key="history.id">
                                <td class="pl-0">
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ formatTime(history.scanned_at) }}</span
                    >
                                </td>
                                <td>
                    <span
                            class="label label-lg label-inline"
                            v-bind:class="`label-light-${
                        history.cipher_updated ? 'success' : 'warning'
                      }`"
                    >{{
                        history.cipher_updated
                          ? $t("YES")
                          : $t("NO")
                      }}</span
                    >
                                </td>
                                <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ history.latitude }}</span
                    >
                                </td>
                                <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ history.longitude }}</span
                    >
                                </td>
                                <td class="pr-0 text-right">
                                    <a
                                            v-if="history.latitude && history.longitude"
                                            v-on:click="selectTag(history.id, false)"
                                            class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                        <inline-svg
                                src="media/svg/icons/Map/Position.svg"
                                :title="$t('VIEW')"
                        ></inline-svg>
                          <!--end::Svg Icon-->
                      </span>
                                    </a>
                                    <a v-else class="btn btn-icon btn-light btn-sm">
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                        <inline-svg
                                src="media/svg/icons/Map/Position.svg"
                                :title="$t('VIEW')"
                        ></inline-svg>
                          <!--end::Svg Icon-->
                      </span>
                                    </a>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                    <b-pagination
                            v-if="history.length > pagination.per_page"
                            class="pt-5"
                            v-model="pagination.current_page"
                            :total-rows="history.length"
                            :per-page="pagination.per_page"
                            align="right"
                    >
                    </b-pagination>
                </div>
                <!--end::Table-->
            </div>
            <!--end::Body-->
        </div>
    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import {latLng} from "leaflet";
    import {LMap, LMarker} from "vue2-leaflet";
    import i18nService from "@/core/services/i18n.service.js";
    import moment from "moment";
    import axios from "axios";

    export default {
        name: "tag",
        data() {
            return {
                not_found: false,
                tag: {
                    uid: "",
                    status: "",
                    seller: "",
                    sold: "",
                },
                history: [],
                last_scan: null,
                last_location: null,
                selected_scan: null,
                selected_location: null,
                map: {
                    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                    attribution:
                        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                    zoom: 11,
                    center: latLng(0, 0),
                    mapOptions: {
                        zoomSnap: 0.5,
                        zoomControl: false,
                    },
                },
                pagination: {
                    current_page: 1,
                    per_page: 10,
                },
            };
        },
        mounted() {
            ApiService.get("tag/" + this.$route.params.uid)
                .then((data) => {
                    this.tag = data.data;
                    if (data.headers.breadcrumbs) {
                        var breadcumbs = JSON.parse(
                            Buffer.from(data.headers.breadcrumbs, "base64").toString("utf-8")
                        );
                        this.$store.dispatch(SET_BREADCRUMB, [
                            {
                                title: breadcumbs.product.name,
                                route: "/product/" + breadcumbs.product.id,
                            },
                            {
                                title: breadcumbs.color.name,
                                route: "/color/" + breadcumbs.color.id,
                            },
                            //COLORCODE
                            {
                                title: breadcumbs.color.code,
                                route: "/color/" + breadcumbs.color.id,
                            },
                            {
                                title: breadcumbs.size.name,
                                route: "/size/" + breadcumbs.size.id,
                            },
                            {title: data.data.uid},
                        ]);
                    }
                })
                .then(() => ApiService.get("tag/" + this.$route.params.uid + "/history"))
                .then(({data}) => {
                    this.history = data;
                    if (this.history.length) {
                        this.selectTag(this.history[0].id, true);
                    }
                })
                .catch(() => {
                    this.not_found = true;
                    this.$store.dispatch(SET_BREADCRUMB, []);
                });
            moment.locale(i18nService.getActiveLanguage());
        },
        methods: {
            selectTag(id, last) {
                const index = this.history.findIndex((item) => item.id === id);
                this.selected_scan = this.history[index];
                this.lookupGeoName(this.selected_scan, last);
                this.map.center = latLng(
                    this.selected_scan.latitude,
                    this.selected_scan.longitude
                );
                if (last) {
                    this.last_scan = this.selected_scan;
                }
                window.scrollTo(0, 0);
            },
            lookupGeoName(scan, last) {
                if (!scan.latitude || !scan.longitude) {
                    this.selected_location = this.$t("TAG.LOCATION_UNKNOWN");
                    if (last) {
                        this.last_location = this.$t("TAG.LOCATION_UNKNOWN");
                    }
                    return;
                }
                const axios_external = axios.create();
                axios_external.defaults.headers.common = {};
                axios_external
                    .get(
                        `https://nominatim.openstreetmap.org/reverse?lat=${
                            scan.latitude
                            }&lon=${
                            scan.longitude
                            }&format=json&accept-language=${i18nService.getActiveLanguage()}`
                    )
                    .then((response) => {
                        this.selected_location =
                            response.data.address.city + ", " + response.data.address.country;
                        if (last) {
                            this.last_location = this.selected_location;
                        }
                    })
                    .catch(() => {
                        this.selected_location = this.$t("TAG.LOCATION_UNKNOWN");
                        if (last) {
                            this.last_location = this.$t("TAG.LOCATION_UNKNOWN");
                        }
                    });
            },
            parseTagStatus(status) {
                switch (status) {
                    case "REGISTRATION_PENDING":
                        return ["warning", this.$t("TAG.STATUS.REGISTRATION_PENDING")];
                    case "SCAN_PENDING":
                        return ["info", this.$t("TAG.STATUS.SCAN_PENDING")];
                    case "OPERATIONAL":
                        return ["success", this.$t("TAG.STATUS.OPERATIONAL")];
                    default:
                    case "INVALID":
                        return ["danger", this.$t("TAG.STATUS.INVALID")];
                }
            },
            fromNow(datetime) {
                return moment(datetime).fromNow();
            },
            formatTime(datetime) {
                return moment(datetime).format("lll");
            },
        },
        computed: {
            paginatedHistory() {
                const start =
                    (this.pagination.current_page - 1) * this.pagination.per_page,
                    end = start + this.pagination.per_page;
                return this.history.slice(start, end);
            },
        },
        components: {
            LMap,
            LMarker,
        },
    };
</script>
